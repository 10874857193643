<template>
  <v-card-text>
    <v-card style="margin-top: 10px; padding: 5px">
      <v-card-text>
        <p class="display-1 text--primary">Рейтинг</p>
      </v-card-text>
      <v-card-text v-if="userRatingStatus == false">
        <p class="text--primary" style="text-align: center">
          Пока что ваши данные еще не добавились в рейтинг, как только они
          подтянуться, информация здесь станет доступна.
        </p>
      </v-card-text>

      <v-card style="margin-bottom: 30px;">
      <v-container v-if="userRating.access == '1'" id="dropdown-example">
        <v-overflow-btn
          class="my-2"
          :items="dropdown_font"
          label="Выберите группу"
          target="#dropdown-example"
          v-model="adminGroup"
          @change="getRatingGroupData(typeRatingButton, adminGroup)"
        ></v-overflow-btn>
      </v-container>

      <v-row
        v-if="userRating.access == '1'"
        style="width: 100%; padding: 0px"
        align="center"
      >
        <v-col class="text-center" cols="12" sm="4">
          <v-btn @click.prevent="getRatingGroupData('dir')">Директора</v-btn>
        </v-col>
        <v-col class="text-center" cols="12" sm="4">
          <v-btn @click.prevent="getRatingGroupData('it')">IT-эксперты</v-btn>
        </v-col>
        <v-col class="text-center" cols="12" sm="4">
          <v-btn @click.prevent="getRatingGroupData('seller')"
            >Продавцы\Замы</v-btn
          >
        </v-col>
      </v-row>
</v-card>



      <v-card v-if="userRatingStatus == true && userRating.access == '0'" outlined class="mx-auto">
        <v-card-title>
          <div class="text-overline mb-4">{{ userRating.name }}</div>
          <v-spacer></v-spacer>
          <div class="text-overline mb-4">
            {{ userRating.region }} | {{ userRating.shop }}
          </div>
          <v-spacer></v-spacer>
          <div class="text-overline mb-4">
            <strong>Место: {{ userRating.place }}</strong>
          </div>
        </v-card-title>
        <v-list-item three-line>
          <v-list-item-content>
            <v-alert
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >person_pin</v-icon
              ><span style="color: black; font-size: 15px"
                >Должность:
                <strong v-if="userRating.type == 'tl'">Тимлид</strong>
                <strong v-if="userRating.type == 'it'">IT-специалист</strong>
                <strong v-if="userRating.type == 'dir'"
                  >Управляющий магазином</strong
                >
                <strong v-if="userRating.type == 'seller'">Продавец</strong>
                <span>
                  <v-btn
                    v-if="userRating.it_status == '1'"
                    class="mx-2"
                    fab
                    dark
                    x-small
                    outlined
                    color="success"
                    @click="changeUserType()"
                  >
                    <v-icon dark> model_training </v-icon>
                  </v-btn></span
                ></span
              ></v-alert
            >
            <v-alert
              v-if="!showSellerData()"
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >bolt</v-icon
              ><span style="color: black; font-size: 15px"
                >Состояние замка:<strong>
                  <span v-if="userRating.event_status == 'life'">
                    Все хорошо, нет невзгод</span
                  >
                  <span
                    style="color: #cd0700"
                    v-if="userRating.event_status == 'fire'"
                  >
                    Пожар, ваша крепость горит! Добыча золота
                    приостановлена.🔥</span
                  >
                  <span
                    style="color: #cd0700"
                    v-if="userRating.event_status == 'barbarian'"
                  >
                    Внимание! На вас напали варвары, жители разбегаются. Добыча
                    золота приостановлена.💪</span
                  >
                  <span
                    style="color: #cd0700"
                    v-if="userRating.event_status == 'storm'"
                  >
                    Большой ураган пронесся по вашему королевству, нужно время
                    на восстановление. Добыча золота приостановлена.💨</span
                  ></strong
                ></span
              ></v-alert
            >
            <v-alert
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >timeline</v-icon
              ><span style="color: black; font-size: 15px"
                >Баллы: <strong>{{ userRating.total_points }}</strong></span
              ></v-alert
            >
            <v-alert
              v-if="!showSellerData()"
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >paid</v-icon
              ><span style="color: black; font-size: 15px"
                >Монеты: <strong>{{ userRating.money }}</strong></span
              ></v-alert
            >
            <!-- <v-alert
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >question_answer</v-icon
              ><span style="color: black; font-size: 15px"
                >Ответы в игре:
                <strong>{{ userRating.game_answers }}</strong></span
              ></v-alert
            > -->
            <v-alert
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >track_changes</v-icon
              ><span style="color: black; font-size: 15px">
                <span v-if="userRating.type == 'tl'">LFL по обороту IT: </span>
                <span
                  v-if="userRating.type == 'dir' || userRating.type == 'seller'"
                  >Доля IT к ЧТ:
                </span>
                <span v-if="userRating.type == 'it'"
                  >Доля ИТ-сер в обороте ЧТ:
                </span>
                <strong>{{ userRating.kpi1 }}</strong></span
              ></v-alert
            >
            <v-alert
              color="green"
              style="padding: 4px; margin-bottom: 10px"
              dense
              outlined
            >
              <v-icon
                style="
                  margin-right: 5px;
                  background: #4caf50;
                  border: 1px solid #fff;
                  padding: 5px;
                  border-radius: 40px;
                "
                color="#fff"
                >track_changes</v-icon
              ><span style="color: black; font-size: 15px">
                <span v-if="userRating.type == 'tl'">Доля IT к ЧТ: </span>
                <span
                  v-if="userRating.type == 'dir' || userRating.type == 'seller'"
                  >Оборот IT:
                </span>
                <span v-if="userRating.type == 'it'"
                  >Проникновение пакетов настройки См. и Пл. + ДУ + Ремонты:
                </span>
                <strong>{{ userRating.kpi2 }}</strong></span
              ></v-alert
            >
            <span v-if="userRating.type == 'it'">
              <v-alert
                color="green"
                style="padding: 4px; margin-bottom: 10px"
                dense
                outlined
              >
                <v-icon
                  style="
                    margin-right: 5px;
                    background: #4caf50;
                    border: 1px solid #fff;
                    padding: 5px;
                    border-radius: 40px;
                  "
                  color="#fff"
                  >track_changes</v-icon
                ><span style="color: black; font-size: 15px"
                  >Проникновение Пакетов к ТВ:
                  <strong>{{ userRating.kpi3 }}</strong></span
                ></v-alert
              >
              <v-alert
                color="green"
                style="padding: 4px; margin-bottom: 10px"
                dense
                outlined
              >
                <v-icon
                  style="
                    margin-right: 5px;
                    background: #4caf50;
                    border: 1px solid #fff;
                    padding: 5px;
                    border-radius: 40px;
                  "
                  color="#fff"
                  >track_changes</v-icon
                ><span style="color: black; font-size: 15px"
                  >Проникновение Пакетов к ноутбукам и ПК:
                  <strong>{{ userRating.kpi4 }}</strong></span
                ></v-alert
              >
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <span v-if="usersRatingStatus == true">
        <v-card-title>
          <span v-if="userRating.access == '0'">Рейтинг вашей группы {{ userRating.user_group }}</span>
          <span v-if="userRating.access == '1'">Рейтинг выбранной группы {{ adminGroup }}</span>
          
          <!-- <span v-if="userInfo.access == '0'"
          >Рейтинг вашей группы
          <span v-if="userInfo.user_group !== 'reg'"
            >( {{ userInfo.user_group }} )</span
          ></span
        > -->
          <!-- <span v-else>{{ currentRatingType }}</span> -->
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Искать..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="ratingData"
          :search="search"
          :items-per-page="10"
        ></v-data-table>
      </span>
    </v-card>

    <span v-if="userRatingStatus == true">
      <v-dialog v-model="drawer" persistent max-width="500px">
        <v-card>
          <v-card-title> Квест от царя </v-card-title>
          <v-card-text>
            <div class="text--primary" style="font-size: 16px">
              {{ userRating.interview.question }}
            </div>
            <v-radio-group v-model="radios">
              <v-radio
                :label="userRating.interview.answer_1"
                value="1"
              ></v-radio>
              <v-radio
                :label="userRating.interview.answer_2"
                value="2"
              ></v-radio>
              <v-radio
                :label="userRating.interview.answer_3"
                value="3"
              ></v-radio>
              <v-btn
                v-if="radios"
                class="ma-2"
                outlined
                color="#00B202"
                v-on:click="sendQuestAnswer()"
              >
                Отправить ответ
              </v-btn>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-dialog>
    </span>

    <v-dialog v-model="drawer_event" persistent max-width="500px">
      <v-card>
        <v-card-title> Внимание! </v-card-title>
        <v-card-text>
          <div class="text--primary" v-if="userRating.event_status == 'fire'">
            🔥 Ваше королевство в огне, проверьте скорее, есть ли у вас "Защита от
            огня", иначе придется ждать пока ваши здания оправятся от несчастья.
          </div>
          <div class="text--primary" v-if="userRating.event_status == 'storm'">
            💨 Большой ураган бушует в вашем королевтстве, проверьте скорее, есть
            ли у вас "Защита от урагана", иначе придется ждать пока ваши здания
            оправятся от несчастья.
          </div>
          <div class="text--primary" v-if="userRating.event_status == 'barbarian'">
            💪 Варвары напали на ваше королевство, проверьте скорее, есть ли у вас
            "Защита от варваров", иначе придется ждать пока ваши здания
            оправятся от несчастья.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            right
            outlined
            color="#00B202"
            v-on:click="closeModal()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      drawer_event: false,
      drawer: false,
      userInfo: JSON.parse(window.localStorage.getItem("user_game")),
      typeRatingButton: 'dir',
      dialogOffline: false,
      adminGroup: "A+",
      currentRatingType: "Рейтинг директоров",
      ratingData: [],
      headers: [],
      dropdown_font: ["A+", "A", "B+", "B-", "C", "C-"],
      search: "",
      userRating: [],
      radios: null,
      userRatingStatus: false,
      usersRatingStatus: false,
    };
  },
  methods: {
    showSellerData() {
      if (
        this.userRating.it_status == "1" &&
        this.userRating.type == "seller"
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeModal() {
      axios
        .post("/backend/rating_user2.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "close_modal",
        })
        .then(({ data }) => {
          this.drawer_event = false;
          console.log(data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    changeUserType() {
      axios
        .post("/backend/rating_user2.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "user",
          load_type: this.userRating.type,
        })
        .then(({ data }) => {
          this.userRating = data[0];
          if (this.userRating.interview.status == "true") {
            this.drawer = true;
          } else {
            this.drawer = false;
          }
          this.userRatingStatus = true;
          this.getUserRatingData(this.userRating.type);
        })
        .catch(({ response }) => {
          console.log(response);
          this.userRatingStatus = false;
          console.log("user data =>" + this.userRatingStatus + response);
        });
    },
    sendQuestAnswer() {
      axios
        .post("/backend/send_answer.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          quest_id: this.userRating.interview.quest_id,
          user_answer: this.radios,
        })
        .then(({ data }) => {
          this.getUserData();
          console.log(data);
        })
        .catch(({ response }) => {
          console.log("error answer" + response);
        });
    },
    getUserData() {
      axios
        .post("/backend/rating_user2.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "user",
        })
        .then(({ data }) => {
          this.userRating = data[0];
          if (this.userRating.interview.status == "true") {
            this.drawer = true;
          } else {
            this.drawer = false;
          }
          this.userRatingStatus = true;
          this.getUserRatingData();

          if (this.userRating.modal_status == "1") {
            this.drawer_event = true;
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.userRatingStatus = false;
          console.log("user data =>" + this.userRatingStatus + response);
        });
    },
    getUserRatingData(userChangeType) {
      axios
        .post("/backend/rating_user2.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "rating",
          user_type: this.userRating.type,
          user_group: this.userRating.user_group,
          load_type: userChangeType,
        })
        .then(({ data }) => {
          this.ratingData = data;
          this.usersRatingStatus = true;

          if (this.userRating.type == "tl") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "LFL по обороту IT", value: "kpi1" },
              { text: "Доля IT к ЧТ", value: "kpi2" },
            ];
          }
          if (this.userRating.type == "dir") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT к ЧТ", value: "kpi1" },
              { text: "Оборот IT", value: "kpi2" },
            ];
          }
          if (this.userRating.type == "it") {
            this.headers = [
              { text: "Регион", align: "start", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT в обороте ЧТ", value: "kpi1" },
              {
                text: "Проникновение пакетов Смартфона и Планшета",
                value: "kpi2",
              },
              { text: "Проникновение Пакетов к ТВ", value: "kpi3" },
              { text: "Проникновение Пакетов к ноутбукам и ПК", value: "kpi4" },
            ];
          }
          if (this.userRating.type == "seller") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT к ЧТ", value: "kpi1" },
              { text: "Оборот IT", value: "kpi2" },
            ];
          }
        })
        .catch(({ response }) => {
          this.usersRatingStatus = false;
          console.log("rating data =>" + this.usersRatingStatus + response);
        });
    },
    getRatingGroupData(userType, adminGroup) {
      if (this.userRating.access == '1') {
        if (adminGroup) {
          this.adminGroup = adminGroup;
        }

        if (userType == null) {
          var type = "dir";
        } else {
          type = userType;
          this.typeRatingButton = type;
        }
        console.log("rating = " + type);
        if (type == "dir") {
          this.currentRatingType = "Рейтинг директоров";
        }
        if (type == "it") {
          this.currentRatingType = "Рейтинг IT-экспертов";
        }
        if (type == "sellers") {
          this.currentRatingType = "Рейтинг продавцов";
        }
        var user_group = this.adminGroup;
      } else {
        // this.adminGroup = this.userInfo.user_group;
        user_group = 'A';
        type = 'dir';
      }
      console.log("rating2 = " + type);

      axios
        .post(
          "/backend/rating_user2.php",
          {
            employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
            type_user: type,
            user_group: user_group,
            type: 'admin_data'
          }
        )
        .then(({ data }) => {
          // console.log("Rating data: " + data.place);
          this.ratingData = data;
        })
        .catch(({ response }) => {
          alert(response.data.message);
        });

          if (type == "tl") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Группа", value: "user_group" },
              { text: "Тип", value: "type" },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "LFL по обороту IT", value: "kpi1" },
              { text: "Доля IT к ЧТ", value: "kpi2" },
            ];
          }
          if (type == "dir") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Группа", value: "user_group" },
              { text: "Тип", value: "type" },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT к ЧТ", value: "kpi1" },
              { text: "Оборот IT", value: "kpi2" },
            ];
          }
          if (type == "it") {
            this.headers = [
              { text: "Регион", align: "start", value: "region" },
              { text: "Группа", value: "user_group" },
              { text: "Тип", value: "type" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT в обороте ЧТ", value: "kpi1" },
              {
                text: "Проникновение пакетов Смартфона и Планшета",
                value: "kpi2",
              },
              { text: "Проникновение Пакетов к ТВ", value: "kpi3" },
              { text: "Проникновение Пакетов к ноутбукам и ПК", value: "kpi4" },
            ];
          }
          if (type == "seller") {
            this.headers = [
              {
                text: "ФИО",
                align: "start",
                sortable: false,
                value: "name",
              },
              { text: "Группа", value: "user_group" },
              { text: "Тип", value: "type" },
              { text: "Регион", value: "region" },
              { text: "Магазин", value: "shop" },
              { text: "Место", value: "place" },
              { text: "Баллы", value: "total_points" },
              // { text: "Ответы в игре", value: "game_answers" },
              { text: "Доля IT к ЧТ", value: "kpi1" },
              { text: "Оборот IT", value: "kpi2" },
            ];
          }
    },
  },
  beforeMount() {
    // this.getUserRatingData();
    this.getUserData();
  },
};
</script>

<style>
.my_alert {
  padding: 4px;
  margin-bottom: 10px;
}
</style>