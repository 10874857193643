<template>
  <span>
    <p>Назначить события</p>
    <v-text-field
      v-model="eventNumber"
      label="Количество жертв в группе"
      required
    ></v-text-field>
    <v-btn @click="startEvents()" class="ma-2" outlined color="#00B202">
      Генерировать события
    </v-btn>
    <v-card v-if="eventStatus">
      <v-card-title>Пользователи с событиями</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Имя</th>
                <th class="text-left">Тип</th>
                <th class="text-left">Группа</th>
                <th class="text-left">Табельный</th>
                <th class="text-left">Событие</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="userData in eventUsers" :key="userData.employeeNumber">
                <td>{{ userData.name }}</td>
                <td>{{ userData.type }}</td>
                <td>{{ userData.group }}</td>
                <td>{{ userData.employeeNumber }}</td>
                <td>{{ userData.event }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    userData: function () {
      return JSON.parse(window.localStorage.getItem("user_game"));
    },
  },
  data() {
    return {
      eventNumber: 5,
      eventUsers: [],
      eventStatus: false,
    };
  },
  methods: {
    startEvents() {
      axios
        .post("/backend/events_users.php", {
          employeeNumber: this.userData.id,
          number: this.eventNumber,
        })
        .then(({ data }) => {
          this.eventUsers = data;
          this.eventStatus = true;
        })
        .catch(({ response }) => {
          console.log("data =>" + response);
        });
    },
  },
};
</script>