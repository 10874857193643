<template>
    <svg
    v-if="status == true"
  id="map"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="848"
  height="536"
  viewBox="0 0 848 536"
  style="margin-left: auto;
    margin-right: auto;
    display: block;"
><g id="background"><image
      id="background_image"
      width="1568"
      height="991"
      transform="scale(0.5)"
      xlink:href="../assets/map/profile_map.png"
    /></g>
    <g id="building">
      
      <image
      v-if="shopData.archery_status"
      id="archery"
      width="350"
      height="350"
      transform="translate(204.62 97.71) scale(0.64)"
      xlink:href="../assets/buildings/archery_range.png"
    /><image
    v-if="shopData.blacksmith"
      id="blacksmith"
      width="350"
      height="350"
      transform="translate(546.68 58.12) scale(0.61)"
      xlink:href="../assets/buildings/blacksmith.png"
    /><image
      id="castle"
      width="500"
      height="500"
      transform="translate(418.71 128.14) scale(0.43)"
      :xlink:href="'https://digital-services.com.ua/beta/img/maps/'+shopData.user_model+'.png'"
    /><image
    v-if="shopData.barrack"
      id="barracks"
      width="350"
      height="350"
      transform="translate(478.05 265.81) scale(0.65)"
      xlink:href="../assets/buildings/barracks.png"
    /><image
    v-if="shopData.tower_status"
      width="350"
      height="350"
      transform="translate(62.56 170.87) scale(0.67)"
      xlink:href="../assets/buildings/tower.png"
    /></g></svg>

</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      shopData: [],
      status: false
    };
  },
    methods: {
    getData() {
      axios
        .post("/backend/get_build.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
        })
        .then(({ data }) => {
          this.shopData = data[0]
          console.log('build=> '+this.shopData.tower_status)
          this.status = true
          console.log('sdfsdfsdfsdfsdf====')
        })
        .catch(({ response }) => {
          console.log(response);
          console.log('----------------')
        });
    },
    },
    beforeMount(){
      this.getData()
    }
}

  </script>