<template>
  <span>
    <v-expansion-panels style="margin-bottom: 20px">
      <v-expansion-panel @click="clearData()">
        <v-expansion-panel-header>
          Статистика ответов
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn
            style="margin: 10px"
            elevation="2"
            :loading="createFileStatus"
            @click="getReportsFileReports()"
            >Сформировать файл csv</v-btn
          >
          <v-btn
            color="success"
            elevation="2"
            :disabled="!fileDataStatus"
            v-bind:href="fileDataSrc"
            >Скачать</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @click="clearData()">
        <v-expansion-panel-header>
          Статистика авторизаций
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn
            style="margin: 10px"
            elevation="2"
            :loading="createFileStatus"
            @click="getAuthFileReports()"
            >Сформировать файл csv</v-btn
          >
          <v-btn
            color="success"
            elevation="2"
            :disabled="!fileDataStatus"
            v-bind:href="fileDataSrc"
            >Скачать</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-snackbar color="success" timeout="1000" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fileDataStatus: false,
      fileDataSrc: "",
      snackbar: false,
      snackbarText: "",
      createFileStatus: false,
    };
  },
  methods: {
      clearData(){
          this.fileDataStatus = false
          this.fileDataSrc = ""
          this.snackbar = false
          this.snackbarText = ""
          this.createFileStatus = false
      },
    getReportsFileReports() {
      this.createFileStatus = true;
      axios
        .post("/backend/get_reports.php", {
          type: "get_report_answers",
        })
        .then(({ data }) => {
          this.fileDataSrc = data.src;
          this.snackbarText = "Файл сформирован";
          this.snackbar = true;
          this.createFileStatus = false;
          this.fileDataStatus = true;
        })
        .catch(({ response }) => {
          console.log("file data =>" + response);
        });
    },
    getAuthFileReports() {
      this.createFileStatus = true;
      axios
        .post("/backend/get_reports.php", {
          type: "get_report_auth",
        })
        .then(({ data }) => {
          this.fileDataSrc = data.src;
          this.snackbarText = "Файл сформирован";
          this.snackbar = true;
          this.createFileStatus = false;
          this.fileDataStatus = true;
        })
        .catch(({ response }) => {
          console.log("file data =>" + response);
        });
    },
  },
};
</script>

