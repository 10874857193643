<template>
  <div class="home" style="margin-top: -60px">
    <loginComponent v-if="!isLoggedIn" />

    <v-container
      style="width: 95%; margin-top: 60px; margin-left: auto; margin-right: auto;}"
      v-else
    >
      <!-- control panel -->
      <v-card class="mx-auto">
        <!-- header -->
        <!-- <span style="height=300px"> -->

        <v-img style="height: 300px" src="../assets/map/profile2.png">
          <v-app-bar flat color="rgba(0, 0, 0, 0)">
            <!-- <v-toolbar-title class="title white--text pl-0">
              Киев 13
            </v-toolbar-title> -->
            <v-btn @click="dialog_local = true" class="mx-2" fab dark small color="success">
              <v-icon dark> map </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
            v-if="buttonStatus == true"
              @click="dialog = true"
              class="mx-2"
              fab
              dark
              small
              color="success"
            >
              <v-icon dark> public </v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>

        <!-- <v-stage ref="stage" :config="configKonva">
            <v-layer ref="layer">
              <v-image :config="configImg"></v-image>
            </v-layer>
          </v-stage>
        </span> -->

        <!-- buttons -->

        <v-col
          style="
            margin-top: -35px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <v-row align="center" justify="space-around">
            <v-btn
              @click.prevent="statusDataBlock = 'rating'"
              color="success"
              fab
              x-large
              dark
            >
              <v-icon>trending_up</v-icon>
            </v-btn>
            <v-btn
              @click.prevent="statusDataBlock = 'bank'"
              color="success"
              fab
              x-large
              dark
            >
              <v-icon>paid</v-icon>
            </v-btn>
            <v-btn
              @click.prevent="statusDataBlock = 'shop'"
              color="success"
              fab
              x-large
              dark
            >
              <v-icon>storefront</v-icon>
            </v-btn>
            <!-- <v-btn
              @click.prevent="statusDataBlock = 'awards'"
              color="success"
              fab
              x-large
              dark
            >
              <v-icon>emoji_events</v-icon>
            </v-btn> -->
          </v-row>
        </v-col>

        <!-- information blocks -->
        <transition name="slide-fade"
          ><bankComponent v-if="statusDataBlock == 'bank'" />
          <ratingComponent v-if="statusDataBlock == 'rating'" />
          <shopComponent v-if="statusDataBlock == 'shop'" />
          <avardsComponent v-if="statusDataBlock == 'awards'" />
          <adminComponent v-if="statusDataBlock == 'admin'" />
        </transition>
      </v-card>
    </v-container>

    <v-card-text style="height: 100px; position: relative">
      <v-btn
        v-if="status == true"
        @click.stop="statusDataBlock = 'admin'"
        absolute
        color="#4caf50b8"
        fab
        right
        bottom
        style="position: fixed; margin-bottom: 90px"
      >
        <v-icon style="font-size: 34px" color="white">settings_suggest</v-icon>
      </v-btn>
    </v-card-text>

    <v-dialog
      style="
        box-shadow: 0 11px 15px -7px rgb(0 0 0 / 0%),
          0 24px 38px 3px rgb(0 0 0 / 0%), 0 9px 46px 8px rgb(0 0 0 / 0%);
        width: min-content;
      "
      v-model="dialog"
    >
      <mapComponent @modal-status="setModalStatus()" />
    </v-dialog>

    <v-dialog
      style="
        box-shadow: 0 11px 15px -7px rgb(0 0 0 / 0%),
          0 24px 38px 3px rgb(0 0 0 / 0%), 0 9px 46px 8px rgb(0 0 0 / 0%);
        width: min-content;
      "
      v-model="dialog_local"
    >
      <mapLocal @modal-status="setModalStatus()" />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

import loginComponent from "../components/Login.vue";
import bankComponent from "../components/controlPanelBlocks/bank.vue";
import ratingComponent from "../components/controlPanelBlocks/rating.vue";
import shopComponent from "../components/controlPanelBlocks/shop.vue";
import avardsComponent from "../components/controlPanelBlocks/awards.vue";
import mapComponent from "../components/Maps.vue";
import mapLocal from "../components/Buildmap.vue";
import adminComponent from "../components/Admin.vue";

export default {
  components: {
    loginComponent,
    bankComponent,
    ratingComponent,
    shopComponent,
    avardsComponent,
    mapComponent,
    adminComponent,
    mapLocal,
  },
  data() {
    return {
      testImg: new Image(),
      configKonva: {
        width: 5000,
        height: 250,
      },
      statusDataBlock: "rating",
      dialog: false,
      dialog_local: false,
      status: false,
      buttonStatus: false
    };
  },
  name: "Home",
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    // console.log(params.get("code"));
    var code = params.get("code");
    console.log(code);

    if (code && !this.$store.getters.isLoggedIn) {
      this.$store
        .dispatch("login", { code })
        .then(() => console.log("AUTH " + this.$store.getters.isLoggedIn))
        .catch((err) => console.log(err));
      // setTimeout(() => {
      //   this.redirectToLinkHome();
      // }, 1000);
    }
  },
  computed: {
    configImg: function () {
      return {
        x: 0,
        y: 0,
        image: this.testImg,
        width: 1000,
        height: 300,
        draggable: true,
      };
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userData: function () {
      return JSON.parse(window.localStorage.getItem("user"));
    },
  },
  mounted() {
    this.testImg.onload = () => this.$refs.layerImg.getStage().draw();
    this.testImg.src = "http://digital-services.com.ua/beta/img/castle.png";
  },
  methods: {
        checkSellerStatus() {
      axios
        .post("/backend/check_seller.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
        })
        .then(({ data }) => {
          console.log('seller= > '+data.message);
          this.buttonStatus = data.message;
          
        })
        .catch(({ response }) => {
          console.log(response);
          this.status = false;
        });
    },
    checkUserStatus() {
      axios
        .post("/backend/check_user.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
        })
        .then(({ data }) => {
          console.log(data);
          this.status = true;
          
        })
        .catch(({ response }) => {
          console.log(response);
          this.status = false;
        });
    },
    setModalStatus() {
      this.dialog = false;
      this.dialog_local = false;
    },
    redirectToLinkHome() {
      window.location.href = "http://digital-services.com.ua/#";
      // здесь выше поставь /beta/# в конец ссылки
    },
    redirectToLink() {
      window.location =
        "https://capf.comfy.ua/auth/web-auth/authorize?client_id=digital-services&response_type=code&redirect_uri=http%3A%2F%2Fdigital-services.com.ua/%2F";
    },
    checkAuth() {},
  },
  beforeMount() {
    this.checkAuth();
    this.checkUserStatus();
    this.checkSellerStatus();
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 0%), 0 24px 38px 3px rgb(0 0 0 / 0%),
    0 9px 46px 8px rgb(0 0 0 / 0%);
}
</style>