<template>
  <v-card style="background: #fff0;">
    <v-card-text>
<svg
v-if="status == true"
  id="map"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="848"
  height="536"
  viewBox="0 0 848 536"
  style="margin-left: auto;
    margin-right: auto;
    display: block;"
><defs>


  <image
      id="junior_n"
      width="500"
      height="500"
      xlink:href="../assets/map/junior.png"
    />
  <image
      id="junior_f"
      width="500"
      height="500"
      xlink:href="../assets/map/junior_f.png"
    />
  <image
      id="junior_s"
      width="500"
      height="500"
      xlink:href="../assets/map/junior_s.png"
    />
  <image
      id="junior_b"
      width="500"
      height="500"
      xlink:href="../assets/map/junior_b.png"
    />


  <image
      id="middle_n"
      width="500"
      height="500"
      xlink:href="../assets/map/middle.png"
    />
  <image
      id="middle_f"
      width="500"
      height="500"
      xlink:href="../assets/map/middle_f.png"
    />
  <image
      id="middle_s"
      width="500"
      height="500"
      xlink:href="../assets/map/middle_s.png"
    />
  <image
      id="middle_b"
      width="500"
      height="500"
      xlink:href="../assets/map/middle_b.png"
    />

      <image
      id="senior_n"
      width="500"
      height="500"
      xlink:href="../assets/map/senior.png"
    />
  <image
      id="senior_f"
      width="500"
      height="500"
      xlink:href="../assets/map/senior_f.png"
    />
  <image
      id="senior_s"
      width="500"
      height="500"
      xlink:href="../assets/map/senior_s.png"
    />
  <image
      id="senior_b"
      width="500"
      height="500"
      xlink:href="../assets/map/senior_b.png"
    />
    </defs>
    
    <g id="background"><image
      id="background_image"
      width="1696"
      height="1072"
      transform="scale(0.5)"
      xlink:href="../assets/map/global_map2.png"
    /></g>
    
    <g id="models">
      
      <g v-if="shopData[0]" id="shop_18"><use
        id="junior_model"
        transform="translate(87.11 411.74) scale(0.21)"
        :xlink:href="'#'+shopData[0].user_model"
      /><text class="cls-1" transform="translate(88.05 518.01)">{{ shopData[0].shop }}</text></g>
        
        
        <g v-if="shopData[1]" id="shop_17"><use
        id="junior_model-2"
        data-name="junior_model"
        transform="translate(64.88 300.63) scale(0.21)"
        :xlink:href="'#'+shopData[1].user_model"
      /><text class="cls-1" transform="translate(65.73 408.13)">{{ shopData[1].shop }}</text></g>
        
        
        <g v-if="shopData[2]" id="shop_16"><use
        id="junior_model-3"
        data-name="junior_model"
        transform="translate(35.3 200.45) scale(0.21)"
        :xlink:href="'#'+shopData[1].user_model"
      /><text class="cls-1" transform="translate(36.21 307.32)">{{ shopData[2].shop }}</text></g>

        <g v-if="shopData[3]" id="shop_15"><use
        id="junior_model-4"
        data-name="junior_model"
        transform="translate(49.05 87.63) scale(0.21)"
        :xlink:href="'#'+shopData[3].user_model"
      /><text class="cls-1" transform="translate(49.22 194.44)">{{ shopData[3].shop }}</text></g>
        
        
        <g v-if="shopData[4]" id="shop_14"><use
        id="junior_model-5"
        data-name="junior_model"
        transform="translate(262.52 18.6) scale(0.21)"
        :xlink:href="'#'+shopData[4].user_model"
      /><text class="cls-1" transform="translate(263.46 125.46)">{{ shopData[4].shop }}</text></g>
      
      <g v-if="shopData[15]" id="shop_13"><use
        id="junior_model-6"
        data-name="junior_model"
        transform="translate(510.19 30.29) scale(0.21)"
        :xlink:href="'#'+shopData[15].user_model"
      /><text class="cls-1" transform="translate(509.98 137.16)">{{ shopData[15].shop }}</text></g>
        
        <g v-if="shopData[5]" id="shop_12"><use
        id="junior_model-7"
        data-name="junior_model"
        transform="translate(653.03 4.16) scale(0.21)"
        :xlink:href="'#'+shopData[5].user_model"
      /><text class="cls-1" transform="translate(654.38 109.59)">{{ shopData[5].shop }}</text></g>
        
        
        <g v-if="shopData[14]" id="shop_11"><use
        id="junior_model-8"
        data-name="junior_model"
        transform="translate(620.01 112.65) scale(0.21)"
        :xlink:href="'#'+shopData[14].user_model"
      /><text class="cls-1" transform="translate(620.36 220.15)">{{ shopData[14].shop }}</text></g>
      
      <g v-if="shopData[6]" id="shop_10"><use
        id="junior_model-9"
        data-name="junior_model"
        transform="translate(704.84 194.01) scale(0.21)"
        :xlink:href="'#'+shopData[6].user_model"
      /><text class="cls-1" transform="translate(705.34 300.63)">{{ shopData[6].shop }}</text></g>
        
        <g v-if="shopData[7]" id="shop_9"><use
        id="junior_model-10"
        data-name="junior_model"
        transform="translate(525.33 191.26) scale(0.21)"
        :xlink:href="'#'+shopData[7].user_model"
      /><text class="cls-1" transform="translate(526.74 297.88)">{{ shopData[7].shop }}</text></g>
        
        <g v-if="shopData[8]" id="shop_8"><use
        id="junior_model-11"
        data-name="junior_model"
        transform="translate(656.47 310.26) scale(0.21)"
        :xlink:href="'#'+shopData[8].user_model"
      /><text class="cls-1" transform="translate(657.44 416.89)">{{ shopData[8].shop }}</text></g>
        
        <g v-if="shopData[9]" id="shop_7"><use
        id="junior_model-12"
        data-name="junior_model"
        transform="translate(704.84 417.24) scale(0.21)"
        :xlink:href="'#'+shopData[9].user_model"
      /><text class="cls-1" transform="translate(705.34 524.38)">{{ shopData[9].shop }}</text></g>
        
        <g v-if="shopData[17]" id="shop_6"><use
        id="junior_model-13"
        data-name="junior_model"
        transform="translate(577.14 426.52) scale(0.21)"
        :xlink:href="'#'+shopData[17].user_model"
      /><text class="cls-1" transform="translate(576.65 532.76)">{{ shopData[17].shop }}</text></g>
        
        <g v-if="shopData[10]" id="shop_5"><use
        id="junior_model-14"
        data-name="junior_model"
        transform="translate(502.38 322.89) scale(0.21)"
        :xlink:href="'#'+shopData[10].user_model"
      /><text class="cls-1" transform="translate(503.61 430.52)">{{ shopData[10].shop }}</text></g>
        
        <g v-if="shopData[11]" id="shop_4"><use
        id="junior_model-15"
        data-name="junior_model"
        transform="translate(381.31 413.89) scale(0.21)"
        :xlink:href="'#'+shopData[11].user_model"
      /><text class="cls-1" transform="translate(380.79 520.69)">{{ shopData[11].shop }}</text></g>
        
        <g v-if="shopData[16]" id="shop_3"><use
        id="junior_model-16"
        data-name="junior_model"
        transform="translate(243.04 417.24) scale(0.21)"
        :xlink:href="'#'+shopData[16].user_model"
      /><text class="cls-1" transform="translate(244.15 524.45)">{{ shopData[16].shop }}</text></g>
        
        <g v-if="shopData[12]" id="shop_2"><use
        id="junior_model-17"
        data-name="junior_model"
        transform="translate(294.85 310.26) scale(0.21)"
        :xlink:href="'#'+shopData[12].user_model"
      /><text class="cls-1" transform="translate(295.37 418.51)">{{ shopData[12].shop }}</text></g>
        
        <g v-if="shopData[13]" id="shop_1"><use
        id="junior_model-18"
        data-name="junior_model"
        transform="translate(321.46 197.01) scale(0.21)"
        :xlink:href="'#'+shopData[13].user_model"
      /><text class="cls-1" transform="translate(322.28 303.63)">{{ shopData[13].shop }}</text></g></g></svg>




    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      square: {
        x: 100,
        y: 100,
      },
      dragOffsetX: null,
      dragOffsetY: null,
      shopData: [],
      status: false
    };
  },
  computed: {
    cursor() {
      return `cursor: ${this.dragOffsetX ? "grabbing" : "grab"}`;
    },
  },
  methods: {
    getData() {
      axios
        .post("/backend/get_map_shops.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
        })
        .then(({ data }) => {
          this.shopData = data
          this.status = true
          console.log('test=> '+this.shopData)
          console.log('test2=> '+this.shopData[0].user_model)
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    coordinates(payload, type) {
      var data = "";
      if (type == "x") {
        data = Number(this.square.x) + Number(payload);
      }
      if (type == "y") {
        data = Number(this.square.y) + Number(payload);
      }
      return data;
    },
    clickHandler() {
      this.$emit("modal-status");
    },

    drag({ offsetX, offsetY }) {
      this.dragOffsetX = offsetX - this.square.x;
      this.dragOffsetY = offsetY - this.square.y;
      this.$refs.box.addEventListener("mousemove", this.move);
    },
    drop() {
      this.dragOffsetX = this.dragOffsetY = null;
      this.$refs.box.removeEventListener("mousemove", this.move);
    },
    move({ offsetX, offsetY }) {
      this.square.x = offsetX - this.dragOffsetX;
      this.square.y = offsetY - this.dragOffsetY;
    },
  },
  beforeMount(){
    this.getData();
  }
};
</script>

          <style>
.cls-1{font-size:12px;fill:#fff;font-family:MyriadPro-Regular, Myriad Pro;}.cls-2{letter-spacing:-0.01em;}.cls-3{letter-spacing:-0.02em;}.cls-4{letter-spacing:-0.01em;}.cls-5{letter-spacing:-0.02em;}.cls-6{letter-spacing:-0.02em;}
.box {
  margin: auto;
  position: center;
  display: block;
}
</style>