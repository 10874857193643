<template>
  <v-card-text style="margin-top: 20px">
    <v-card class="mx-auto">
      <v-card-text>
        <p class="display-1 text--primary">Казна</p>
        <v-alert color="green" dense outlined>
          <v-icon style="margin-right: 5px" color="#FF9E02"
            >monetization_on</v-icon
          >В вашей казне:
          <strong>{{ buildData.user_money }}</strong> монет</v-alert
        >
        <v-alert color="green" dense outlined>
          <v-icon style="margin-right: 5px" color="#50b154"
            >monetization_on</v-icon
          >Доход за здания:
          <strong>+{{ buildData.build_money }}</strong> монет</v-alert
        >

        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel style="border: 1px solid #4caf50">
            <v-expansion-panel-header disable-icon-rotate>
              Постройки
              <template v-slot:actions>
                <v-icon color="#4caf50"> foundation </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="buildData.build_count == 0">
              Пока у вас нет ни одной постройки.
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else>
              <v-container>
                <v-row dense>
                  <span
                    style="max-width: 250px; margin-left: -23px;"
                    v-for="data in buildData.build_list"
                    :key="data.product"
                  >
                    <v-col :cols="$vuetify.breakpoint.mobile">
                      <v-card min-height="530px">
                        <v-img
                          :src="data.img"
                          class="white--text align-end"
                          height="200px"
                          contain
                        >
                        </v-img>
                        <v-card-title v-text="data.product"></v-card-title>
                        <div
                          style="
                            margin-left: 10px;
                            margin-top: -20px;
                            margin-bottom: -15px;
                          "
                        ></div>
                        <v-card-text>
                          <div class="text--primary">
                            Прибыль: <strong>{{ data.value }}</strong>
                            <p>{{ data.description }}</p>
                          </div></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </span>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel style="border: 1px solid #4caf50">
            <v-expansion-panel-header disable-icon-rotate>
              Способности
              <template v-slot:actions>
                <v-icon color="#4caf50"> auto_fix_high </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="buildData.ability_count == 0">
              Пока у вас нет ни одной защиты.
            </v-expansion-panel-content>

            <v-expansion-panel-content v-else>
              <v-container>
                <v-row dense>
                  <span
                    style="max-width: 250px; margin-left: -23px;"
                    v-for="data in buildData.ability_list"
                    :key="data.product"
                  >
                    <v-col :cols="$vuetify.breakpoint.mobile">
                      <v-card min-height="530px">
                        <v-img
                          :src="data.img"
                          class="white--text align-end"
                          height="200px"
                          contain
                        >
                        </v-img>
                        <v-card-title v-text="data.product"></v-card-title>
                        <div
                          style="
                            margin-left: 10px;
                            margin-top: -20px;
                            margin-bottom: -15px;
                          "
                        ></div>
                        <v-card-text>
                          <div class="text--primary">
                            Защита от:
                            <strong>
                              <span v-if="data.protection == 'fire'">
                                огня 🔥</span
                              >
                              <span v-if="data.protection == 'storm'">
                                урагана 💨</span
                              >
                              <span v-if="data.protection == 'barbarian'">
                                варваров 💪</span
                              >
                            </strong>
                            <p>{{ data.description }}</p>
                          </div></v-card-text
                        >
                      </v-card>
                    </v-col>
                  </span>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      buildData: [],
      panel: [0, 1],
    };
  },
  methods: {
    getBuildData() {
      console.log("get data blyed = >");
      axios
        .post("/backend/get_building_data.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
        })
        .then(({ data }) => {
          this.buildData = data[0];
          console.log("data1=>| " + data[0].user_money);
          console.log("data2=>| " + data[0].build_list[0].product);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  beforeMount() {
    this.getBuildData();
  },
};
</script>
