<template>
  <span>
    <v-expansion-panels style="margin-bottom: 20px">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Редактировать товары
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Искать..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="shopData"
            :search="search"
            :items-per-page="10"
          >
            <template v-slot:item.src="{ item }">
              <v-img
                max-height="50"
                max-width="50"
                style="margin: 5px"
                :src="item.src"
              ></v-img>
            </template>
            <template v-slot:item.edit="{ item }">
              <div class="text-center">
                <v-btn
                  style="margin-right: 3px"
                  @click="getDataEditProduct(item.edit)"
                  fab
                  x-small
                  color="success"
                >
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                  @click="deleteProduct(item.edit)"
                  fab
                  x-small
                  color="error"
                >
                  <v-icon dark> delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Данные о покупках </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn style="margin: 10px;" elevation="2" :loading="createFileStatus" @click="getBuyProductList()">Сформировать файл csv</v-btn>
          <v-btn color="success" elevation="2" :disabled="!fileDataStatus" v-bind:href="fileDataSrc">Скачать</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Импорт товаров </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>Фотографии нужно загружать по адресу "digital-services.com.ua/beta/img/product"</p>

          <v-btn
            x-small
            outlined
            color="success"
            v-bind:href="'https://digital-services.com.ua/backend/files/product.xlsx'"
          >
            Скачать образец таблицы
          </v-btn>
            <v-file-input
              v-model="file"
              ref="file"
              show-size
              counter
              multiple
              label="Выберите xlsx файл"
            ></v-file-input>
            <v-btn
              class="ma-2"
              outlined
              color="#00B202"
              v-on:click="submitFile()"
            >
              Загрузить файл
            </v-btn>

          <v-card
            v-if="statusData != 'no_data'"
            class="mx-auto"
            max-width="344"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="#00B202"
                  indeterminate
                  v-if="statusData == 'load_data'"
                ></v-progress-circular>
                <div
                  v-if="statusData == 'load_data'"
                  style="text-align: center"
                  class="text-overline mb-4"
                >
                  Данные обрабатываются
                </div>
                <div
                  v-if="statusData == 'true_data'"
                  color="#00B202"
                  style="text-align: center"
                  class="text-overline mb-4"
                >
                  <span class="group pa-2">
                    <v-icon
                      style="
                        margin-right: auto;
                        margin-left: auto;
                        display: block;
                      "
                      color="#00B202"
                      x-large
                      >done_all</v-icon
                    >
                  </span>
                  Данные загружены
                </div>
                <div
                  v-if="statusData == 'false_data'"
                  color="red"
                  style="text-align: center"
                  class="text-overline mb-4"
                >
                  Ошибка, данные не загрузились
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row v-if="dialog" justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Редактировать товар</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="editProductData.title"
                  label="Изменить название товара"
                  required
                ></v-text-field>

                <v-textarea
                  :counter="500"
                  clearable
                  clear-icon="mdi-close-circle"
                  v-model="editProductData.description"
                  label="Изменить описание товара"
                  required
                ></v-textarea>

                <v-select
                  v-model="editProductData.select"
                  :hint="`${editProductData.select.state}, ${editProductData.select.abbr}`"
                  :items="items"
                  item-text="state"
                  item-value="abbr"
                  label="Select"
                  persistent-hint
                  return-object
                  single-line
                ></v-select>

                <v-select
                  v-if="editProductData.select.state == 'способность'"
                  v-model="editProductData.protection_type"
                  :hint="`${editProductData.protection_type.state}, ${editProductData.protection_type.abbr}`"
                  :items="itemsProteck"
                  item-text="state"
                  item-value="abbr"
                  label="Select"
                  persistent-hint
                  return-object
                  single-line
                ></v-select>

                <v-text-field
                  v-if="editProductData.select.state == 'постройка'"
                  v-model="editProductData.currency"
                  label="Доход со здания"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="editProductData.price"
                  label="Цена"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="editProductData.quantity"
                  label="Количество"
                  required
                ></v-text-field>

                <v-switch
                  v-model="editProductData.status"
                  label="Статус (включено\выключено)"
                ></v-switch>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="dialog = false">
              Закрыть
            </v-btn>
            <v-btn color="success darken-1" text @click="editProduct()">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-snackbar color="success" timeout="1000" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [],
      search: "",
      shopData: [],
      dialog: false,
      items: [
        { state: "Товар", abbr: "product" },
        { state: "Способность", abbr: "ability" },
        { state: "Постройка", abbr: "building" },
      ],
      itemsProteck: [
        { state: "Огонь", abbr: "fire" },
        { state: "Ураган", abbr: "storm" },
        { state: "Варвары", abbr: "barbarians" },
      ],
      editProductData: [],
      snackbar: false,
      snackbarText: "",
      createFileStatus: false,
      fileDataStatus: false,
      fileDataSrc: '',
      file: "",
      statusData: "no_data",
    };
  },
  methods: {
    submitFile() {
      this.statusData = "load_data";

      var formData = new FormData();
      formData.append("file", this.file[0]);
      console.log(this.file[0]);

      axios
        .post("/backend/import_product.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          this.statusData = "true_data";
          console.log("succes " + data);
          this.getUserShopData();
        })
        .catch(({ response }) => {
          this.statusData = "false_data";
          console.log("failure " + response);
        });
    },
        getBuyProductList() {
      axios
        .post("/backend/get_cart_data.php", {
          type: "get_wallet_product",
        })
        .then(({ data }) => {
          this.createFileStatus = true;
          this.fileDataSrc = data.src;
          this.snackbarText = "Файл сформирован";
          this.snackbar = true;
          this.createFileStatus = false;
          this.fileDataStatus = true;
        })
        .catch(({ response }) => {
          console.log("file data =>" + response);
        });
    },
    deleteProduct(product_id) {
      axios
        .post("/backend/get_cart_data.php", {
          type: "delete_product",
          id: product_id,
        })
        .then(({ data }) => {
          this.getUserShopData();
          console.log(data);
          this.snackbarText = "Товар удален";
          this.snackbar = true;
        })
        .catch(({ response }) => {
          console.log("shop data =>" + response);
        });
    },
    editProduct() {
      axios
        .post("/backend/get_cart_data.php", {
          type: "edit_product",
          id: this.editProductData.id,
          title: this.editProductData.title,
          description: this.editProductData.description,
          type_product: this.editProductData.select.abbr,
          protect_product: this.editProductData.protection_type.abbr,
          price: this.editProductData.price,
          status: this.editProductData.status,
          quantity: this.editProductData.quantity,
          currency: this.editProductData.currency,
        })
        .then(({ data }) => {
          this.editProductData = data[0];
          this.getUserShopData();
          this.dialog = false;
          this.snackbarText = "Товар отредактирован";
          this.snackbar = true;
        })
        .catch(({ response }) => {
          console.log("shop data =>" + response);
          this.dialog = false;
        });
    },
    getDataEditProduct(product_id) {
      axios
        .post("/backend/get_cart_data.php", {
          type: "get_admin_product",
          product_data: "true",
          id: product_id,
        })
        .then(({ data }) => {
          this.editProductData = data[0];
          console.log("TEST=> " + this.editProductData.select.state);
          this.dialog = true;
          console.log('TEST=> => '+this.editProductData.select.state)
        })
        .catch(({ response }) => {
          console.log("shop data =>" + response);
        });
    },
    getUserShopData() {
      axios
        .post("/backend/get_cart_data.php", {
          type: "get_admin_product",
        })
        .then(({ data }) => {
          this.shopData = data;
          this.headers = [
            {
              text: "Фото",
              align: "start",
              sortable: false,
              value: "src",
            },
            { text: "Название", value: "title" },
            { text: "Описание", value: "description" },
            { text: "Тип", value: "type" },
            { text: "Цена", value: "price" },
            { text: "Статус", value: "status" },
            { text: "Количество", value: "quantity" },
            { text: "Валюта", value: "currency" },
            { text: "Редактировать", value: "edit" },
          ];
        })
        .catch(({ response }) => {
          console.log("shop data =>" + response);
        });
    },
  },
  beforeMount() {
    this.getUserShopData();
  },
};
</script>