import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token_game') || '',
    user: localStorage.getItem('user_game') || '',
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      localStorage.removeItem('token_game')
      localStorage.removeItem('user_game')
    },
  },
  actions: {
    login({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios
          .post('/backend/login.php', {
            data: loginData
          })
          .then(({ data }) => {
            const token = data.token
            const userData = data.user

            // console.log('LOGIN DATA user => '+data.user)
            // console.log('LOGIN DATA token => '+data.token)
            // console.log('LOGIN DATA error => '+data.error)

            localStorage.setItem('token_game', token)
            localStorage.setItem('user_game', JSON.stringify(userData))

            axios.defaults.headers.common['token'] = token
            commit('auth_success', token, userData)
            resolve(data)
          })
          .catch(({ response }) => {
            // alert(response.data.message);
            commit('auth_error')
            localStorage.removeItem('token')
            reject(response)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userData: (state) => state.user,
    getToken: (state) => state.token,
  },
  modules: {},
})
