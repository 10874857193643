<template>
  <v-card-text style="margin-top: 20px">
    <v-btn
      @click.stop="cart = !cart"
      absolute
      color="#4caf50b8"
      fab
      left
      bottom
      style="position: fixed; margin-bottom: 100px"
    >
      <v-badge :color="setStyleBadgeCart()" :content="cartLength">
        <v-icon style="font-size: 34px" color="white"
          >shopping_basket</v-icon
        ></v-badge
      >
    </v-btn>

    <v-dialog v-model="cart" max-width="500px">
      <v-card outlined>
        <v-card-title> Корзина </v-card-title>
        <v-card-text>
          <v-container class="grey lighten-5">
            <v-card outlined tile style="padding: 10px" v-if="itemsCart[0]">
              <div
                v-for="item in itemsCart"
                :key="item.title"
                style="margin-bottom: 50px"
              >
                <v-row class="mb-6" no-gutters>
                  <v-col :md="4">
                    <v-card style="height: 99%" outlined class="pa-2">
                      <v-img
                        :src="item.src"
                        contain
                        class="grey darken-4"
                      ></v-img>
                    </v-card>
                  </v-col>

                  <v-col style="height: 150px" :md="8">
                    <v-card outlined class="pa-2">
                      <v-toolbar-title>{{ item.title }}</v-toolbar-title>

                      <div>
                        Цена
                        <strong style="color: #4caf50">
                          {{ item.price }}
                        </strong>
                        монет {{ item.quantity }}
                      </div>
                      <v-card-text
                        style="
                          margin: -10px;
                          max-height: 100px;
                          overflow: hidden;
                          line-height: 16px;
                        "
                      >
                        {{ item.description }}
                      </v-card-text>
                      <v-btn
                        class="ma-2"
                        outlined
                        color="error"
                        x-small
                        @click="removeProductCart(item.id, item.price)"
                      >
                        удалить из корзины
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                depressed
                @click="purchaseProduct()"
                color="success"
                style="margin-left: auto"
              >
                Купить
              </v-btn>
            </v-card>
            <v-card v-else>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Вы еще ничего не добавили в свою
                    корзину</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            style="margin-left: auto"
            text
            @click="cart = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="mx-auto">
      <v-card-text v-if="statusCardStart">
        <p class="display-1 text--primary">Рынок</p>
        <p class="text--primary">
          У вас:
          <strong style="color: #4caf50"> {{ myMoney }} </strong> монет<v-icon
            color="#FF9E02"
            >paid</v-icon
          >
          | Товаров в корзине на сумму
          <strong style="color: #4caf50"> {{ moneyCart }} </strong> монет
        </p>

        <div style="margin-bottom: 10px">
          <v-expansion-panels>
            <v-expansion-panel style="border: 1px solid #4caf50">
              <v-expansion-panel-header disable-icon-rotate>
                Мои покупки
                <template v-slot:actions>
                  <v-icon color="#4caf50"> all_inbox </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="myBuyProduct.length <= 0">
                Пока еще нет ни одной покупки
              </v-expansion-panel-content>

              <v-expansion-panel-content v-else>
        <v-container>
          <v-row dense>
            <span
              style="max-width: 250px"
              v-for="card in myBuyProduct"
              :key="card.title"
            >
              <v-col
              >
                <v-card min-height="530">
                  <v-img
                    :src="card.src"
                    class="white--text align-end"
                    height="200px"
                    contain
                  >
                  </v-img>
                  <v-card-title v-text="card.title"></v-card-title>
                  <div
                    style="
                      margin-left: 10px;
                      margin-top: -20px;
                      margin-bottom: -15px;
                    "
                  ></div>
                  <v-card-text>
                    <div class="text--primary">
                      {{ card.description }}
                    </div></v-card-text
                  >
                </v-card>
              </v-col>
            </span>
          </v-row>
        </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <v-alert color="green" dense outlined>
          <v-icon
            style="
              margin-right: 5px;
              background: #4caf50;
              border: 1px solid #fff;
              padding: 5px;
              border-radius: 40px;
            "
            color="#fff"
            >history_edu</v-icon
          ><span style="color: black; font-size: 13px"
            >Вы можете приобрести здесь физические товары, способности - которые
            помогут защитится от пагубных событий в игре и прочее...</span
          ></v-alert
        >
        <v-btn
          class="ma-2"
          @click="productFilter = 'product'"
          :outlined="productFilter != 'product'"
          :depressed="productFilter == 'product'"
          color="success"
          align="center"
        >
          <v-icon left> shopping_bag </v-icon> Товары
        </v-btn>
        <v-btn
          class="ma-2"
          @click="productFilter = 'ability'"
          :outlined="productFilter != 'ability'"
          :depressed="productFilter == 'ability'"
          color="success"
          align="center"
        >
          <v-icon left> auto_fix_high </v-icon> Способности
        </v-btn>
        <v-btn
          class="ma-2"
          @click="productFilter = 'building'"
          :outlined="productFilter != 'building'"
          :depressed="productFilter == 'building'"
          color="success"
          align="center"
        >
          <v-icon left> foundation </v-icon> Постройки
        </v-btn>
        <v-container>
          <v-row dense>
            <span
              style="max-width: 250px"
              v-for="card in cards"
              :key="card.title"
            >
              <v-col
                v-if="card.type == productFilter"
                :cols="$vuetify.breakpoint.mobile"
              >
                <v-card min-height="530px">
                  <v-img
                    :src="card.src"
                    class="white--text align-end"
                    height="200px"
                    contain
                  >
                  </v-img>
                  <v-card-title v-text="card.title"></v-card-title>
                  <div
                    style="
                      margin-left: 10px;
                      margin-top: -20px;
                      margin-bottom: -15px;
                    "
                  ></div>
                  <v-card-text>

                   <div v-if="card.quantity == 0" class="text--primary">
                      Товара нет в наличии
                    </div>
                    <div v-else class="text--primary">
                      {{ card.description }}
                    </div></v-card-text
                  >

                  <v-card-actions>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><span
                          v-if="card.status == 0"
                            style="
                              width: 150px;
                              margin-left: auto;
                              margin-right: auto;
                              display: block;
                              border: 1px solid #4caf50;
                              padding: 5px;
                              border-radius: 3px;
                            "
                            >Цена
                            <strong style="color: #4caf50">{{
                              card.price
                            }}</strong>
                            золота</span
                          ></v-list-item-title
                        >
                        <v-btn
                          @click.stop="
                            addProductToCart(
                              card.title,
                              card.description,
                              card.price,
                              card.src,
                              card.id,
                              card.type
                            )
                          "
                          :disabled="
                            Number(moneyCart) + Number(card.price) > myMoney ||
                            card.status == 1 || card.quantity == 0 || checkProductInCart(card.id)
                          "
                          class="ma-2"
                          color="success"
                        >
                          <v-icon left> shopping_basket </v-icon>
                          <span v-if="!checkProductInCart(card.id)">В корзину</span>
                          <span v-else>В корзине</span>
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-col>
            </span>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <p class="display-1 text--primary">Рынок</p>
        <p class="text--primary" style="text-align: center">
          Рынок на данный момент недоступен и наполняется новыми товарами,
          ожидайте пожалуйста его открытия.
        </p>
      </v-card-text>
    </v-card>

    <v-snackbar color="success" timeout="1000" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </v-card-text>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    myMoney: 0,
    moneyCart: 0,
    productFilter: "product",
    snackbar: false,
    snackbarText: "",
    cart: false,
    cartLength: 0,
    costs: 0,
    itemsCart: [],
    cards: [],
    cradCols: 6,
    statusCardStart: true,
    myBuyProduct: []
  }),
  methods: {
        getUserBuyProduct() {
     axios
        .post("/backend/get_cart_data.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "get_my_product",
        })
        .then(({ data }) => {
          this.myBuyProduct = data
        })
        .catch(({ response }) => {
          console.log(response);
        });
 
    },
    getUserMoney() {
     axios
        .post("/backend/get_cart_data.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          type: "get_money",
        })
        .then(({ data }) => {
          this.myMoney = data.money
        })
        .catch(({ response }) => {
          console.log(response);
          this.myMoney = 0;
        });
 
    },
    
    handleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    checkProductInCart(productId){
      var indexProductInItems;
      indexProductInItems = this.findIndex(this.itemsCart, productId);
      if (this.itemsCart[indexProductInItems]) {
        return true;
      }else{
        return false
      }
    },
    addProductToCart(
      productTitle,
      productDescription,
      productPrice,
      productSrc,
      productId,
      productType
    ) {
      var indexProductInItems;
      // indexProductInItems = this.itemsCart.indexOf({id: productId});
      indexProductInItems = this.findIndex(this.itemsCart, productId);
      console.log("index product in cart " + indexProductInItems);

      if (this.itemsCart[indexProductInItems]) {
        console.log("test yes");
        var quantityThisProduct = this.itemsCart[indexProductInItems].quantity;
        quantityThisProduct++;

        this.itemsCart = this.replaceTitle(this.itemsCart, [
          this.itemsCart[indexProductInItems].id,
          quantityThisProduct,
        ]);
      } else {
        this.itemsCart.push({
          title: productTitle,
          price: productPrice,
          src: productSrc,
          description: productDescription,
          id: productId,
          type: productType,
          quantity: 1,
        });
      }

      var finalMoneyCart = Number(this.moneyCart) + Number(productPrice);
      this.moneyCart = finalMoneyCart;

      this.cartLength = this.itemsCart.length;
      console.log("this is CART = " + this.itemsCart[0].title);
      this.setSnackbar("addToCart");
    },
    replaceTitle(oldArray, [itemId, newTitle]) {
      let newArray = JSON.parse(JSON.stringify(oldArray));
      (newArray.find(({ id }) => id === itemId) || {}).quantity = newTitle;
      return newArray;
    },
    removeProductCart(productId, price) {
      var index = this.findIndex(this.itemsCart, productId);
      if (!index) {
        console.log("cart error");
      }

      if (this.itemsCart[index].quantity > 1) {
        var thisItem = this.itemsCart[index].quantity;
        var newItem = Number(thisItem) - 1;
        console.log("current item quantity = " + newItem);
        this.itemsCart[index].quantity = newItem;

        var thisCartLength = this.cartLength;
        var newCartLength = Number(thisCartLength) - 1;
        this.cartLength = newCartLength;
      } else {
        this.itemsCart.splice(index, 1);
        this.cartLength = this.itemsCart.length;
      }
      this.setSnackbar("removeFromCart");
      var moneyBackCart = Number(this.moneyCart) - Number(price);
      this.moneyCart = moneyBackCart;
    },
    findIndex(arr, key) {
      for (var i = 0, l = arr.length; i < l; i++) {
        if (arr[i].id == key) {
          return i;
        }
      }
      return false;
    },
    setStyleBadgeCart() {
      if (this.cartLength > 0) {
        return "rgb(255 158 2)";
      } else {
        return "none";
      }
    },
    setSnackbar(type) {
      if (type == "addToCart") {
        this.snackbarText = "Товар добавлен в корзину";
      }
      if (type == "removeFromCart") {
        this.snackbarText = "Товар удален из корзины";
      }
      this.snackbar = true;
    },
    getProductList() {
      axios
        .post("/backend/get_cart_data.php", {employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,})
        .then(({ data }) => {
          this.cards = data;
          this.getUserMoney();
          // alert(data);
        })
        .catch(({ response }) => {
          alert(response.data.message);
        });
    },
    purchaseProduct() {
      axios
        .post("/backend/purcase_product.php", {
          employeeNumber: JSON.parse(window.localStorage.getItem("user_game"))
            .id,
          product_list: this.itemsCart,
        })
        .then((data) => {
          console.log("cart true => " + data);
          this.cart = false;
          this.itemsCart = [];
          this.cartLength = 0;
          this.getUserMoney();
          this.getUserBuyProduct();
          this.moneyCart = 0;
        })
        .catch(() => {
          console.log("invalid product");
        });
    },
  },
  computed: {
    cols() {
      const { lg, sm } = this.$vuetify.breakpoint;
      return lg ? [3, 9] : sm ? [3, 9] : [3, 9];
    },
  },
  beforeMount() {
    this.getProductList();
    this.getUserBuyProduct();
  },
};
</script>