<template>
  <span style="margin-top: 50px; margin-right: 20px; margin-left: 20px">
    <v-card class="mx-auto mt-12" max-width="450" style="background: #ffffffa3">
      <v-col align="center">
        <v-toolbar-title style="text-align: center"
          >авторизация</v-toolbar-title
        >
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click.prevent="redirectToLink()"
        >
          Авторизация Comfy
          <v-icon right dark> fingerprint </v-icon>
        </v-btn>
      </v-col>
    </v-card>
  </span>
</template>

<script>
export default {
  methods: {
    redirectToLink() {
      // здесь ниже добавить /beta в конец ссылки
      window.location =
        "https://capf.comfy.ua/auth/web-auth/authorize?client_id=digital-services&response_type=code&redirect_uri=http%3A%2F%2Fdigital-services.com.ua%2F";
    },
  },
};
</script>

