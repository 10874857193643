<template>
  <v-app id="app" app-data="true">
    <img
      v-if="!isLoggedIn"
      class="logo_style"
      style="text-align: center"
      src="http://digital-services.com.ua/beta/img/logo_game5_2.png"
    />
    <!-- v-if="!isLoggedIn" -->

    <router-view />
<!-- кнопка уведомлений -->
    <!-- <v-card-text style="height: 100px; position: relative">
      <v-btn
        @click.stop="drawer = !drawer"
        absolute
        color="#4caf50b8"
        fab
        left
        bottom
        style="position: fixed; margin-bottom: 90px"
        v-if="isLoggedIn"
      >
        <v-badge color="rgb(255 158 2)" content="6">
          <v-icon style="font-size: 34px;" color="white">notifications</v-icon></v-badge
        >
      </v-btn>
    </v-card-text> -->

    <!-- <v-dialog v-model="drawer" max-width="500px">
      <v-card>
        <v-card-title> Уведомления </v-card-title>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="drawer = false"> Закрыть </v-btn>

          <v-list-item @click="logout" link>
            <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userData: function () {
      return JSON.parse(window.localStorage.getItem("user_game"));
    },
  },
  data() {
    return {
      drawer: false,
      items: [
        { title: "Меню", icon: "history", url: "/" },
        { title: "Меню", icon: "history", url: "/history" },
        { title: "Меню", icon: "history", url: "/instruction" },
      ],
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });

    if (this.$store.getters.isLoggedIn) {
      axios
        .post("/backend/validate_token.php", {
          token: this.$store.getters.getToken,
        })
        .then(() => {
          console.log("valid token");
        })
        .catch(() => {
          console.log("invalid token");
          this.$store.dispatch("logout");
          this.$router.push("/");
        });
    }
  },
};
</script>

<style>
.v-application--wrap {
  background-image: url(assets/login_background2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0px;
  background-color: #000000;
  opacity: 1;
}

.logo_style {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 300px;
}
</style>