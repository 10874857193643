<template>
  <span>
    <v-btn
      x-small
      outlined
      color="success"
      v-bind:href="'https://digital-services.com.ua/backend/files/rating_draft.xlsx'"
    >
      Скачать образец таблицы
    </v-btn>
    <v-file-input
      v-model="file"
      ref="file"
      show-size
      counter
      multiple
      label="Выберите xlsx файл"
    ></v-file-input>
    <v-btn class="ma-2" outlined color="#00B202" v-on:click="submitFile()">
      Загрузить файл
    </v-btn>

    <v-card
      v-if="statusData != 'no_data'"
      class="mx-auto"
      max-width="344"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-progress-circular
            :size="70"
            :width="7"
            color="#00B202"
            indeterminate
            v-if="statusData == 'load_data'"
          ></v-progress-circular>
          <div
            v-if="statusData == 'load_data'"
            style="text-align: center"
            class="text-overline mb-4"
          >
            Данные обрабатываются
            <p>{{ status }}</p>
          </div>
          <div
            v-if="statusData == 'true_data'"
            color="#00B202"
            style="text-align: center"
            class="text-overline mb-4"
          >
            <span class="group pa-2">
              <v-icon
                style="margin-right: auto; margin-left: auto; display: block"
                color="#00B202"
                x-large
                >done_all</v-icon
              >
            </span>
            Данные загружены
          </div>
          <div
            v-if="statusData == 'false_data'"
            color="red"
            style="text-align: center"
            class="text-overline mb-4"
          >
            Ошибка, данные не загрузились
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </span>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      file: "",
      statusData: "no_data",
      status: 'test',
    };
  },
  methods: {
    submitFile() {
      this.statusData = "load_data";

      var formData = new FormData();
      formData.append("file", this.file[0]);
      console.log(this.file[0]);

      this.checkStatusUpdate();

      axios
        .post("/backend/update_rating.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          this.statusData = "true_data";
          console.log("succes " + data);
        })
        .catch(({ response }) => {
          this.statusData = "false_data";
          console.log("failure " + response);
        });
    },
    checkStatusUpdate(){
      axios
        .post("/backend/rating_update_status.php", {})
        .then(({ data }) => {
          this.status = data.status;
          if(data.status !== 'end'){
            this.getStatusUpdate()
          }
          
        })
        .catch(({ response }) => {
          console.log("file data =>" + response);
        });
    },
    getStatusUpdate(){
        setTimeout(this.checkStatusUpdate, 1000);
    }
  },
};
</script>