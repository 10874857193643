<template>
  <v-card-text style="margin-top: 20px">
    <v-card class="mx-auto">
      <v-card-text>
        <p class="display-1 text--primary">Админ панель</p>

      <v-card style="margin-bottom: 10px;" outlined>
        <v-card-text>
        <v-row align="center" justify="space-around">
          <v-btn style="margin: 5px" @click="currentAdminPanel = 'shop'" :disabled="currentAdminPanel == 'shop'">Магазин</v-btn>
          <v-btn style="margin: 5px" @click="currentAdminPanel = 'event'" :disabled="currentAdminPanel == 'event'">События</v-btn>
          <v-btn style="margin: 5px" @click="currentAdminPanel = 'reports'" :disabled="currentAdminPanel == 'reports'">Отчеты</v-btn>
          <v-btn style="margin: 5px" @click="currentAdminPanel = 'rating'" :disabled="currentAdminPanel == 'rating'">Рейтинг</v-btn>
        </v-row>
        </v-card-text>
      </v-card>

      <shopComponent v-if="currentAdminPanel == 'shop'" />
      <reportsComponent v-if="currentAdminPanel == 'reports'" />
      <ratingComponent v-if="currentAdminPanel == 'rating'" />
      <eventComponent v-if="currentAdminPanel == 'event'" />


      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import shopComponent from "../components/adminPanel/shop.vue"
import reportsComponent from "../components/adminPanel/reports.vue"
import ratingComponent from "../components/adminPanel/rating.vue"
import eventComponent from "../components/adminPanel/event.vue"

export default {
  components: {
    shopComponent,
    reportsComponent,
    ratingComponent,
    eventComponent,
  },
  data() {
    return {
      currentAdminPanel: 'shop'
    };
  },
};
</script>

